import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgCategoriesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height="24px" viewBox="0 0 21 21" width="24px" {...props}>
    <path
      d="M12.572 3.91h5v5h-5zm-8.333 8.334h5v5h-5zm8.333 2.5a2.5 2.5 0 1 0 5 0 2.5 2.5 0 0 0-5 0M4.239 6.41a2.5 2.5 0 1 0 5 0 2.5 2.5 0 0 0-5 0"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);
const Memo = memo(SvgCategoriesIcon);
export default Memo;
